import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RxCross2 } from "react-icons/rx";

function HamburgerMenu({ setOpen, isOpen }) {
  const navigate = useNavigate();
  const closeNav = () => {
    setOpen(false);
  };

  return (
    <Container isOpen={isOpen}>
      e
      <Wrapper>
        <Logo
          onClick={() => {
            closeNav();
            navigate("/");
          }}
          src="/image/digiso.png"
        />

        <Navs>
          <Anchor onClick={closeNav} to="/about">
            About Us
          </Anchor>
          <Anchor onClick={closeNav} to="how-we-differ">
            How we differ
          </Anchor>
          <Anchor onClick={closeNav} to="/ad-formats">
            Ad Formats
          </Anchor>
          <Anchor as="a" href="https://blog.digiso.ai/blog/">
            Blogs
          </Anchor>
          <Anchor as="a" href="https://digiso.ai/contact/index.php">
            Contact Us
          </Anchor>
        </Navs>

        <Menu onClick={closeNav}>
          <RxCross2 />
        </Menu>
      </Wrapper>
    </Container>
  );
}

export default HamburgerMenu;

const Container = styled.div`
  transition: all 300ms ease-out;
  transform: ${(p) =>
    p.isOpen === true ? "translateX(0)" : "translateX(100%)"};
  max-width: 100vw;
  max-height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  position: fixed;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img`
  height: 2.5rem;
  position: absolute;
  top: 3vh;
  left: 5vw;
`;

const Navs = styled.div`
  text-align: center;
`;

const Anchor = styled(Link)`
  display: block;
  text-decoration: none;
  margin: 1.5rem 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #000;
  position: relative;
  font-size: 2rem;
  @media screen and (max-width: 500px) {
    font-size: 1.75rem;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 3vh;
  right: 5vw;
  font-weight: bold;
  svg {
    font-size: 2.5rem;
  }
`;
