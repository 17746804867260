import React from "react";
import styled from "styled-components";
import adformats from "../components/Ad-Formats/adformats";
import AdFormatBlock from "../components/Ad-Formats/AdFormatBlock";

function AdFormats() {
  return (
    <Container>
      <Wrapper>
        <Head>
          <Heading>Ad formats</Heading>
          <p>
            We use standard formats as well as our own innovative creations.
            Choose from a vast assortment of banners and videos as well as
            popular Vignettes, Sticky Ads, Interscroller, and other interactive
            options.
          </p>
        </Head>
        <ContainerCard>
          {adformats.map((item, index) => {
            return (
              <AdFormatBlock key={index} title={item.title} data={item.ads} />
            );
          })}
        </ContainerCard>
      </Wrapper>
    </Container>
  );
}

export default AdFormats;

const Container = styled.div`
  max-width: 100vw;
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 0 5%;
`;
const Wrapper = styled.div`
  width: 100%;
`;
const Head = styled.div`
  margin: 5% 0 5%;
  p {
    margin-top: 1rem;
    line-height: 1.75rem;
    width: 90%;
    font-size: 1.25rem;
    text-align: center;
  }
`;
const Heading = styled.h1`
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
`;
const ContainerCard = styled.div``;
