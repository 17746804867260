import React from "react";
import {
  Section,
  Container,
  Wrapper,
  Img,
  ImgContainer,
} from "../../assets/Styles/HeroSection";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

function Advertiser() {
  AOS.init();
  return (
    <Section id="advertiser">
      <Container>
        <Wrap data-aos="fade-left">
          <h1>Advertiser</h1>
          <p>
            Just go out of box and break the conventional teams or momes you are
            workly withwe are team of some professional who not any understands
            has there commitment but have the knowledge , technology and will to
            fulfill there requirements. We not only are Associated with some of
            the good publishers Across APAC but also are transparent to
            technical strong to emalcuate your creative to Ads.
          </p>
        </Wrap>
        <ImgContainer data-aos="fade-right">
          <Image src="/image/advertiser.png" />
        </ImgContainer>
      </Container>
    </Section>
  );
}

export default Advertiser;

const Wrap = styled(Wrapper)`
  padding: 12% 0;
  padding-left: 5%;
`;

const Image = styled(Img)`
  width: 100%;
`;
