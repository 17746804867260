import React from "react";
import {
  Section,
  Container,
  Wrapper,
  Img,
  ImgContainer,
} from "../../assets/Styles/HeroSection";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

function Publisher() {
  AOS.init();
  return (
    <Sec id="publisher">
      <Container type="reverse">
        <Wrap data-aos="fade-left">
          <h1>Publisher</h1>
          <p>
            We are not just a programmative Ad network we are a set of
            profession who not only properly monitor each and every publisher
            website but also try to maximaise the publisher reach by our
            Innovation and technology.
          </p>
        </Wrap>
        <ImgContainer data-aos="fade-right">
          <Image src="/image/publisher.png" />
        </ImgContainer>
      </Container>
    </Sec>
  );
}

export default Publisher;
const Sec = styled(Section)`
  background-color: #f2f2f2;
`;

const Wrap = styled(Wrapper)`
  padding: 12% 0;
  padding-right: 5%;
`;
const Image = styled(Img)`
  width: 100%;
`;
