import styled from "styled-components";
import Button from "./Button";

const Section = styled.section`
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding: 0;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10%;
  align-items: center;
  flex-direction: ${(p) => p.type === "reverse" && "row-reverse"};
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const Tophead = styled.h3`
  margin-left: 1rem;
  letter-spacing: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;
const Wrapper = styled.div`
  flex: 1;
  h1 {
    display: block;
    width: 100%;
    font-size: 3rem;
    font-weight: regular !important;
    margin-bottom: 1rem;
    @media screen and (min-width: 0px) and (max-width: 1024px) {
      text-align: center;
    }
  }
  p {
    margin-bottom: 1.75rem;
    word-break: keep-all;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
`;
const ImgContainer = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  min-height: 300px;
`;
const Img = styled.img`
  width: 80%;
`;
const Buttons = styled.div`
  display: flex;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const BtnL = styled(Button)`
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    margin-bottom: 0rem;
    font-size: 1rem;
    margin-top: 1.25rem;
  }
`;
const Btn = styled(BtnL)`
  background-color: #fff;
  color: #000;
  margin-left: 1rem;
  background: #f9f9f9;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    margin-left: 0rem;
    margin-top: 1rem;
    background: #f2f2f2;
  }

  &:hover {
    background-color: #bfbfbf;
  }
`;

const Shape = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;
  bottom: -15%;
  display: none;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`;

export {
  Section,
  ImgContainer,
  Container,
  Wrapper,
  Btn,
  BtnL,
  Buttons,
  Img,
  Tophead,
  Shape,
};
