import React from "react";
import styled from "styled-components";

function PrivacyPolicy() {
  return (
    <Wrapper>
      <Container>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy governs the manner in which Digiso AI collects,
          uses, maintains, and discloses information collected from users (each,
          a "User") of the Digiso AI website ("Site"). This privacy policy
          applies to the Site and all products and services offered by Digiso
          AI.
        </p>

        <div>
          <h2>Personal Identification Information</h2>
          <p>
            We may collect personal identification information from Users in
            various ways, including but not limited to when Users visit our
            site, register on the site, fill out a form, and in connection with
            other activities, services, features, or resources we make available
            on our Site. Users may be asked for, as appropriate, name, email
            address, mailing address, and phone number. We will collect personal
            identification information from Users only if they voluntarily
            submit such information to us. Users can always refuse to supply
            personal identification information, except that it may prevent them
            from engaging in certain Site-related activities.
          </p>
        </div>
        <div>
          <h2>Non-personal Identification Information</h2>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer, and
            technical information about Users' means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized, and other similar information.
          </p>
        </div>
        <div>
          <h2>Web Browser Cookies</h2>
          <p>
            Our Site may use "cookies" to enhance the User experience. User's
            web browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies or to alert them
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly..
          </p>
        </div>
        <div>
          <h2>How We Use Collected Information</h2>
          <p>
            Digiso AI may collect and use Users' personal information for the
            following purposes:
          </p>
          <ul>
            <li>
              {" "}
              To improve customer service: Information you provide helps us
              respond to your customer service requests and support needs more
              efficiently.
            </li>
            <li>
              To personalize the user experience: We may use information in the
              aggregate to understand how our Users as a group use the services
              and resources provided on our Site
            </li>
            <li>
              To improve our Site: We may use feedback you provide to improve
              our products and services.
            </li>
            <li>
              To send periodic emails: We may use the email address to send User
              information and updates pertaining to their order. It may also be
              used to respond to their inquiries, questions, and/or other
              requests. If the User decides to opt-in to our mailing list, they
              will receive emails that may include company news, updates,
              related product or service information, etc. If at any time the
              User would like to unsubscribe from receiving future emails, we
              include detailed unsubscribe instructions at the bottom of each
              email..
            </li>
          </ul>
        </div>
        <div>
          <h2>How We Protect Your Information</h2>
          <p>
            We adopt appropriate data collection, storage, and processing
            practices, and security measures to protect against unauthorized
            access, alteration, disclosure, or destruction of your personal
            information, username, password, transaction information, and data
            stored on our Site.
          </p>
        </div>
        <div>
          <h2>Sharing Your Personal Information</h2>
          <p>
            We do not sell, trade, or rent Users' personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates, and advertisers for the purposes outlined above. We may
            also disclose your personal information in the following situations
          </p>
        </div>
        <div>
          <h2>Sharing Your Personal Information</h2>
          <ul>
            <li>
              {" "}
              Third-party service providers: We may share your information with
              third-party service providers who perform services on our behalf,
              such as hosting, data analysis, payment processing, and customer
              support. These service providers are authorized to use your
              personal information only as necessary to provide services to us
            </li>
            <li>
              Legal requirements: We may disclose personal information if
              required to do so by law or in response to valid requests by
              public authorities (e.g., a court){" "}
            </li>
          </ul>
        </div>
      </Container>
    </Wrapper>
  );
}

export default PrivacyPolicy;

const Wrapper = styled.main`
  max-width: 100vw;
  padding: 5%;
  background-color: #fff;
`;

const Container = styled.main`
  width: 100%;
  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    margin: 2rem 0 1rem;
  }
`;
