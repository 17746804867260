import styled from "styled-components";

const Button = styled.button`
  background-color: ${(p) => p.theme.color.main};
  border: none;
  color: white;
  padding: 1rem 3rem;
  font-weight: bold;
  border-radius: 5000px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.dark};
  }
`;

export default Button;
